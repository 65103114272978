import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import DicomViewModal from '../../../../../components/DicomViewModal/DicomViewModal';
import LiveExam from '../../../../../Services/liveexam.services';
import { getCachedData } from '../../../../../cache/Load.cache';
import CornerStoneDicomViewer from '../../../../../components/CornerStoneDicomViewer';
import { SelectedQuestion } from '../../context';
import SplitDicomViewerCornerStone from '../../../../../components/SplitDicomViewerCornerStone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faExpand } from '@fortawesome/free-solid-svg-icons';
import { getLinkData } from '../../../../../cache/Get.cache';


interface IExamQuestionValue {
    value: any,
    response: any
    statement: any;
}


interface IThumbnails {
    url: string,
    dataId: string
    extension: string
    data: any,
    label: string
}

export default function ExamQuestionValue(props: IExamQuestionValue) {


    const [s3Url, setS3Url] = useState<string>();

    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);

    // const { add, getByID, getByIndex, getAll, deleteRecord } = useIndexedDB("dicom");


    const [loading, setLoading] = useState<boolean>(false);

    const [fileLinks, setFileLinks] = useState<any[]>([]);

    const [thumbnails, setThumbnails] = useState<IThumbnails[]>([]);
    const [showSplitView, setShowSplitView] = useState<boolean>(false);
    const [showDicomView, setShowDicomView] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(-1);


    // const requestS3Url = async () => {
    //     await LiveExam.getS3url().then((res) => {
    //         if (res.status === 200) {
    //             setS3Url(res.data)
    //         }
    //     })
    // }


    const onMount = async () => {
        const thumbnailData = await LiveExam.getQuestionThumbnails(selectedQuestionId.question).then((res) => {
            if (res.status === 200) {
                return res.data?.data?.thumbNails;
            }
            else {
                return []
            }
        }).catch(e => {
            console.error(e)
            return []
        })

        let finalDataThumb = [];
        for (const data of thumbnailData) {
            const link_data = await getLinkData(data?.thumbnailData)
            const body_data = await link_data.text();
            finalDataThumb.push({
                url: data?.thumbnailData,
                dataId: data?.questionDataId,
                extension: data?.thumbnailExtension,
                data: body_data,
                label: data?.label
            })
        }
        setThumbnails(finalDataThumb)

        setLoading(false)

    }

    const getDicomData = async (dataId: any) => {
        await LiveExam.getQuestionData(dataId).then((res) => {
            if (res.status === 200) {
                const links = res.data.data.uploadedFiles;
                const s3Links = links.map((link: any) => link.fileLink)
                setFileLinks(s3Links)
            }
            else {
                setFileLinks([])
            }
        }).catch(e => {
            console.error(e)
            setFileLinks([])
        })
    }

    useEffect(() => {
        refreshSelectedDicom()
        if (props.value) {
            setLoading(true);
            onMount();
        }
        else {
            setLoading(false);
        }
    }, [props.value, s3Url])


    // useEffect(() => {
    //     requestS3Url();
    // }, [])


    const refreshSelectedDicom = () => {
        setFileLinks([])
    }


    const dicomMultiView = () => {
        return (
            <>
                <Row style={ !fileLinks?.length ? { } : { height: '700px', overflow: 'auto' }}>
                    {thumbnails && thumbnails.length > 0 &&
                        thumbnails.map((thumbnail, index: number) => {
                            return (
                                <Col md={!fileLinks?.length ? 3 : 12} onClick={() => {getDicomData(thumbnail.dataId); setActiveIndex(index)}} className="dcm-link-cursor mt-1 text-center p-0 d-flex align-items-center justify-content-center flex-column">
                                    <Image src={thumbnail.data} width={fileLinks?.length ? 100 : 200} height={fileLinks?.length ? 100 : 200} className={'border rounded p-1' + (activeIndex === index ? ' border-success border-5' : ' border-primary')} />
                                    <div className='mt-1' style={{ fontSize: '10px', maxWidth: fileLinks?.length ? 100 : 200 }}>
                                        {thumbnail.label || ("Dicom " + (index + 1))}
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </>
        )
    }

    useEffect(() => {
        if(!fileLinks?.length && activeIndex !== -1){
            setActiveIndex(-1);
        }
    }, [selectedQuestionId, fileLinks?.length])
    return (
        <>

            <DicomViewModal
                show={showDicomView}
                setShow={() => setShowDicomView(false)}
                links={fileLinks}
                statement={props.statement?.question}
            />

            <SplitDicomViewerCornerStone
                show={showSplitView}
                setShow={() => setShowSplitView(false)}
                links={fileLinks}
                thumbnails={thumbnails}
                statement={props.statement?.question}
            />

            {/* {
                fileLinks.length > 0 &&
                <div className='m-3 p-2 border rounded'>
                    <CornerStoneDicomViewer onClose={() => setFileLinks([])} links={fileLinks} show={fileLinks.length > 0} />
                    <div className="mt-2 d-flex justify-content-center align-items-center">
                        <Button variant="warning" onClick={() => setShowDicomView(true)}>
                            <FontAwesomeIcon icon={faExpand} /> View Full Screen
                        </Button>
                    </div>
                </div>
            }
            {
                loading ? <div className="dicom-half-view">
                    <div className="d-flex justify-content-center align-items-center text-primary">
                        Loading Please Wait...
                    </div>
                </div> :
                    <div>

                        {dicomMultiView()}
                        <div className="d-flex justify-content-start m-4">
                            <Button onClick={() => setShowSplitView(true)}><FontAwesomeIcon icon={faColumns} /> Open Split View</Button>
                        </div>
                    </div>
            } */}

            <Row className='mt-2'>
                <Col md={fileLinks.length > 0 ? 2 : 12} className='p-0'>
                    {
                        loading ? <div className="dicom-half-view">
                            <div className="d-flex justify-content-center align-items-center text-primary">
                                Loading Please Wait...
                            </div>
                        </div> :
                            <div className=''>

                                {dicomMultiView()}
                                <div className="d-flex justify-content-start m-4">
                                    <Button onClick={() => setShowSplitView(true)} size='sm'><FontAwesomeIcon icon={faColumns} /> Open Split View</Button>
                                </div>

                                {/* <CornerStoneDicomViewer
                            onClose={() => setSelectedDicom(undefined)}
                            links={fileLinks}
                        /> */}
                            </div>
                    }
                </Col>
                {
                    fileLinks.length > 0 &&
                    <Col md={10}>
                        <div className='m-1 p-2 border rounded bg-body-tertiary'>
                            <CornerStoneDicomViewer onClose={() => setFileLinks([])} links={fileLinks} show={fileLinks.length > 0} />
                            <div className="mt-2 d-flex justify-content-center align-items-center">
                                <Button variant="warning" onClick={() => setShowDicomView(true)}>
                                    <FontAwesomeIcon icon={faExpand} /> View Full Screen
                                </Button>
                            </div>
                        </div>
                    </Col>
                }
            </Row>

        </>
    )
} 