import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'
import { Container, Form, InputGroup, Button, Alert, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/Modal/Modal';
import PublicService from '../../../Services/public.service';


export default function Authentication() {

    const navigate = useNavigate();
    const [isOnlineServer, setIsOnlineServer] = useState<boolean>(false);
    const [url, setUrl] = useState<string>("");
    const [showDataFromLocalStorage, setShowDataFromLocalStorage] = useState<boolean>(false);
    const [urlType, setUrlType] = useState<string>('http');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");



    const handleUrlChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUrlType(event.target.value)
    }

    const handleSaveDataOnLocalStorage = async () => {
        if (isOnlineServer) {
            setShowModal(true);
        } else {
            if (!url || !urlType) {
                toast.error("Please enter valid url");
                return
            }
            let isLocalServer = await checkLocalServer(urlType + "://" + url);
            console.log(isLocalServer, 'isLocalServer');
            if (isLocalServer) {
                localStorage.setItem('serverType', 'offline')
                localStorage.setItem('localServerUrl', url)
                localStorage.setItem('localServerUrlType', urlType)
                navigate("/login")
            }
        }
    }

    const checkLocalServer = async (localServerUrl: string): Promise<boolean> => {
        setLoading(true);
        try {
            const res = await PublicService.checkLocalServer(localServerUrl);
            if (res.status === 200) {
                console.log(res, 'res');
                setLoading(false);
                return true;
            } else {
                setLoading(false);
                toast.error("Error: Server responded with an unexpected status.");
                setError('Unexpected server response.');
                return false;
            }
        } catch (err) {
            setLoading(false);
            toast.error("Error while checking local server. Please try again later.");
            console.error(err);
            setError('Unable to check local server. Please try again later.');
            return false;
        } finally {
            setLoading(false);
        }
    };

    function handleModalProceed(){
        localStorage.setItem('serverType', 'online');
        navigate("/login");
    }



    const handleRemoveDataOnLocalStorage = () => {
        localStorage.removeItem('serverType')
        localStorage.removeItem('localServerUrl')
        localStorage.removeItem('localServerUrlType')
        window.location.reload()
    }

    useEffect(() => {
        const savedServerType = localStorage.getItem('serverType')
        const savedUrl = localStorage.getItem('localServerUrl')
        const savedUrlType = localStorage.getItem('localServerUrlType')
        if (savedServerType === 'offline') {
            setUrlType(savedUrlType);
            setUrl(savedUrl);
            setIsOnlineServer(false);
            setShowDataFromLocalStorage(true);
        }
    }, [])

    return (
        <div style={{ height: "100vh", backgroundColor: '#eeeeee' }}>
            <Container className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <div style={{ width: "600px" }}>

                    <Form.Group className="mb-2">
                        <Form.Label>Server Type</Form.Label>
                        <Form.Switch
                            checked={isOnlineServer}
                            onChange={() => setIsOnlineServer(!isOnlineServer)}
                            label={isOnlineServer ? "Using Online Server" : "Using Offline Server"}
                        />
                    </Form.Group>

                    <hr />
                    {!isOnlineServer && <>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <Form.Select value={urlType} onChange={handleUrlChange}>
                                    <option selected value="http">http</option>
                                    <option value="https">https</option>
                                </Form.Select>
                            </InputGroup.Text>
                            <Form.Control placeholder="Enter server URL" value={url} onChange={(e: any) => setUrl(e.target.value)} />
                        </InputGroup>
                    </>}
                    <div className="d-flex justify-content-end gap-3">
                        <div className="d-flex align-items-center gap-3">
                            <Button
                                className="w-100"
                                size="sm"
                                variant="secondary"
                                onClick={handleSaveDataOnLocalStorage}
                                disabled={(!isOnlineServer && (!urlType || !url)) || loading}
                            >
                                {
                                    loading ?
                                        <span>Loading...<Spinner size="sm" animation="border" /> </span>
                                        : "Next"
                                }
                            </Button>
                        </div>
                    </div>
                    {showDataFromLocalStorage &&
                        <>
                            <p className="mt-3">Sotred Url Data: <span className='fw-bold fst-italic'>{localStorage.getItem('localServerUrlType')}://{localStorage.getItem('localServerUrl')}</span></p>
                            <div className="d-flex justify-content-center align-items-center mt-3" style={{ cursor: "pointer" }} onClick={handleRemoveDataOnLocalStorage}>
                                <div className="d-flex gap-2">
                                    <FontAwesomeIcon icon={faTrashAlt} className="mt-1 text-danger" />
                                    <p className="text-danger text-decoration-underline">Delete Past Data</p>
                                </div>
                            </div>
                        </>
                    }

                    {
                        error !== '' &&
                        <Alert variant="danger" className='mt-2'>
                            <Alert.Heading>Error:</Alert.Heading>
                            <p>{error} </p>
                            <p>Please try again</p>
                        </Alert>
                    }
                </div>
            </Container>
            <CustomModal
                displayModal={showModal}
                onClose={() => setShowModal(false)}
                modalBody={
                    <>
                        <Alert variant="danger">
                            <Alert.Heading>Warning</Alert.Heading>
                            <p>Using online server will result in higher consumption of network bandwidth. </p><p> Please ensure your internet connection is stable.</p>
                        </Alert>
                        <div className="">

                            <div className="d-flex justify-content-end gap-3">
                                <Button
                                    className="w-100"
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="w-100"
                                    size="sm"
                                    variant="danger"
                                    onClick={handleModalProceed}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </>
                }
            />
        </div>
    )
}

/*
    items sotred in local storage => 
        serverType : online/offline
        localServerUrlType: http/https
        localServerUrl : server url
*/