import { faBroom, faFlag, faFontAwesomeFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { IOptionsData } from '../../view/Private/Exam/ExamQuestionBody/ExamQuestion/ExamQuestion.options';

import "./options.css";
import useAutoSizeTextArea from '../../helpers/useAutoSizeTextArea';

interface ITextOption {
    label: any
    count: number
    onChange: any
    optionId: string,
    defaultValue?: IOptionsData
}

export default function TextOption(props: ITextOption) {

    const textRef = useRef<HTMLTextAreaElement>(null);

    useAutoSizeTextArea(textRef.current, props.defaultValue && props.defaultValue.value ? props.defaultValue.value : "");

    const handleChange = (value: any, name: any) => {
        props.onChange({
            ...props.defaultValue, optionId: props.optionId, [name]: value
        })
    }

    return (
        <div className='mb-4'>
            <Accordion defaultActiveKey={"-0"}>
                <Accordion.Item eventKey={props?.optionId}>
                    <Accordion.Header>
                        <Form.Label className="h5 d-flex justify-content-between align-items-center w-100">
                            <div className="fs-14">
                                <span className="fw-bold">
                                    {String((props.count + 9).toString(36).toUpperCase() + ".")}
                                </span>
                                <span className='ms-1'>
                                    {props.label}
                                </span>
                            </div>
                        </Form.Label>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex align-items-center justify-content-end">
                            <span className='me-2'>
                                <FontAwesomeIcon icon={faBroom} style={{ cursor: "pointer" }} size="sm" onClick={() => {
                                    handleChange("", "value")
                                }} />
                            </span>
                            <span>
                                {
                                    props.defaultValue && props.defaultValue.flag === true ?
                                        <>
                                            <FontAwesomeIcon className="text-warning" icon={faFlag} onClick={() => handleChange(false, "flag")} />
                                        </> :
                                        <>
                                            <FontAwesomeIcon icon={faFontAwesomeFlag} onClick={() => handleChange(true, "flag")} style={{ cursor: "pointer" }} />
                                        </>
                                }
                            </span>
                        </div>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                ref={textRef}
                                onChange={(e: any) => handleChange(e.target.value, "value")}
                                // defaultValue={props.defaultValue && props.defaultValue.value ? props.defaultValue.value : ""}
                                placeholder={props?.label}
                                value={props.defaultValue && props.defaultValue.value ? props.defaultValue.value : ""}
                            />
                        </Form.Group>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}