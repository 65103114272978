import { faClose, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import CornerStoneDicomViewer, { tools, toolsOptions } from './CornerStoneDicomViewer'
import "./DicomViewModal/DicomViewModal.css";
import Select from 'react-select';
import LiveExam from '../Services/liveexam.services'

interface IProps {
  show: boolean
  setShow: any
  links: any;
  thumbnails: any;
  statement: string;
}
export default function SplitDicomViewerCornerStone(props: IProps) {
  const [selectValue, setSelectValue] = useState<any>({ value: '', label: 'Select Dicom' });
  const [selectValue2, setSelectValue2] = useState<any>({ value: '', label: 'Select Dicom' });
  const [linksArray, setLinksArray] = useState<any>([]);
  const [linksArray2, setLinksArray2] = useState<any>([]);
  const [activeTool, setActiveTool] = useState<string>("");

  function handleSelectChange(e: any, actionMeta: any, instance: any) {
    if (actionMeta.action !== "select-option") {
      if (instance === 'second') {
        setSelectValue2(e);
        setLinksArray2([]);
        return;
      }
      setSelectValue(e);
      setLinksArray([]);
      return;
    }

    if (instance === 'second') {
      setSelectValue2(e);
      getDicomData(e.value, 'second');
      return;
    }
    setSelectValue(e);
    getDicomData(e.value, instance);
  }

  const getDicomData = async (dataId: any, instance: string) => {
    await LiveExam.getQuestionData(dataId).then((res) => {
      if (res.status === 200) {
        const links = res.data.data.uploadedFiles;
        const s3Links = links.map((link: any) => link.fileLink);
        if (instance === 'second') {
          setLinksArray2(s3Links);
        } else {
          setLinksArray(s3Links);
        }
      }
    }).catch(e => {
      console.error(e)
    })
  }


  // console.log(linksArray, "linksArray");
  function onModalClose() {
    setLinksArray([]);
    setSelectValue({
      value: '',
      label: 'Select Dicom'
    });
    setLinksArray2([]);
    setSelectValue2({
      value: '',
      label: 'Select Dicom'
    });
    setActiveTool("");
    props.setShow(false);
  }



  return (
    <>
      <Modal show={props.show} fullscreen={true} onHide={onModalClose} centered className="dicom-modal-fullscreen">
        {/* <Modal.Title className="text-white m-3"><FontAwesomeIcon icon={faClose} onClick={onModalClose} className="me-3 dcm-link-cursor" /> Dicom Control View</Modal.Title> */}
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p className='text-white' id="split-dcm-statement-text-question" dangerouslySetInnerHTML={{ __html: props.statement }}></p>
            <Button size='sm' variant='warning' className='mb-2' onClick={onModalClose}> <FontAwesomeIcon icon={faXmark} /> Close</Button>
          </div>
          <Row className=''>
            <Col md={3}>
              <Form.Group>
                <Form.Label className="fw-bold text-white ">Select Dicom 1: </Form.Label>
                <Select
                  className='col-md-6'
                  options={props.thumbnails?.map((data: any, index: number) => {
                    return {
                      value: data.dataId,
                      label: data?.label
                    }
                  })}
                  // isMulti
                  isClearable
                  onChange={(e: any, actionMeta: any) => handleSelectChange(e, actionMeta, 'first')}
                  value={selectValue}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label className="fw-bold text-white">Select Dicom 2: </Form.Label>
                <Select
                  className='col-md-6'
                  options={props.thumbnails?.map((data: any, index: number) => {
                    return {
                      value: data.dataId,
                      label: data?.label
                    }
                  })}
                  // isMulti
                  isClearable
                  onChange={(e: any, actionMeta: any) => handleSelectChange(e, actionMeta, 'second')}
                  value={selectValue2}
                />
              </Form.Group>
            </Col>
            {
              (linksArray?.length > 0 || linksArray2?.length > 0) &&
              <Col md={3}>
                <Form.Group>
                  <Form.Label className="fw-bold text-white">Select Tool: </Form.Label>
                  <Select
                  className='col-md-6'
                    options={toolsOptions}
                    onChange={(option): any => setActiveTool(option.value)}
                    value={toolsOptions.find((option) => option.value === activeTool) || null}
                    placeholder="Select Tool"
                  />
                </Form.Group>
              </Col>
            }
          </Row>
          <Row>
            <Col md={6}>
              <div className="mt-2 me-2 p-1 border rounded">
                {
                  linksArray && linksArray.length > 0 ?
                    <CornerStoneDicomViewer onClose={() => props.setShow(false)} links={linksArray} show={props.show} hideTools={true} activeToolParent={activeTool} imageSize={600} />
                    :
                    <p className='text-white fs-3'>No Dicom Selected</p>
                }
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2 me-2 p-1 border rounded">
                {
                  linksArray2 && linksArray2.length > 0 ?
                    <CornerStoneDicomViewer onClose={() => props.setShow(false)} links={linksArray2} show={props.show} hideTools={true} imageSize={600} />
                    :
                    <p className='text-white fs-3'>No Dicom Selected</p>
                }
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}