import { useContext } from "react";
import { useIndexedDB } from "react-indexed-db";
import makeRequestPublic from "../api/makeRequest.public";
import { RequstMethods } from "../api/requestMethode";
import { unZipFiles } from "../helpers/fs.helper";
import { ExamService } from "../Services/examServices";
import PublicService from "../Services/public.service";
import getCache from "./Get.cache";



export async function downloadDataFromLink(s3Url: string, link: string) {
    let existingCache: (string | undefined)[] = [];
    if ("caches" in window) {
        await caches.open("cache").then(async (cache) => {
            await cache.keys().then(res => {
                existingCache = res.map((data) => {
                    return (data.url.split("/").pop())
                })
            })
        })
    }

    if (existingCache.includes(link)) {
        return true
    }
    else {
        const data = await makeRequestPublic(s3Url + "/" + link, RequstMethods.GET);
        await addDataToCache(data.data, s3Url + "/" + link);
        return true
    }

}

export async function downloadAndStoreToCache(link: string) {
    let existingCache: (string | undefined)[] = [];
    if ("caches" in window) {
        await caches.open("cache").then(async (cache) => {
            await cache.keys().then(res => {
                existingCache = res.map((data) => {
                    return (data.url.split("/").pop())
                })
            })
        })
    }

    if (existingCache.includes(link)) {
        return true;
    }
    else {
        const data = await makeRequestPublic(link, RequstMethods.GET);
        await addDataToCache(data.data, link);
        return true;
    }
}

export async function deleteAllCaches() {
    if ('caches' in window) {
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
            await caches.delete(name);
        }
        console.log('All caches deleted.');
    }
}

//             }

//         }

//         // const downloadCounter = {...counter , questionCounter, examCounter};
//         // setCounter(downloadCounter);
//     }


// for (const exam of liveExams) {
//     const examData = await PublicService.getQuestionDataByExamId(exam);
//     if (examData.data && examData.data.data && examData.data.data.length > 0) {
//         for (const link of examData.data.data) {
//             const data = await getByIndex("_id", link);
//             if (!data) {

//                 await ExamService.getQuestionDataByDataid(link).then(async (res) => {
//                     if (res.status === 200) {
//                         const enc_data = await getCache(examData.data.s3Url + "/" + link);
//                         // const dec_data = await decipherData(enc_data, res.data.data.secretKey)
//                         const unzipped_data = await unZipFiles(enc_data)
//                         if(unzipped_data.length > 0 ){
//                             const added_data = await add({
//                                 _id: link,
//                                 data: unzipped_data
//                             });
//                         }

//                     }
//                 });
//             }
//         }

//     }
// }


// export default async function LoadCache(setCounter: any, counter: any) {

//     const liveExamsReq = await PublicService.getAllLiveExams();
//     const liveExams = liveExamsReq.data.map((data: any) => {
//         return data._id
//     })



//     if (!liveExams || liveExams.length === 0) return true;
//     let examCounter = 0;
//     for (const exam of liveExams) {
//         examCounter = examCounter + 1;
//         const examData = await PublicService.getQuestionDataByExamId(exam);
//         let questionCounter = 0;
//         if (examData.data && examData.data.data && examData.data.data.length > 0) {
//             for (const link of examData.data.data) {
//                 await downloadDataFromLink(examData.data.s3Url, link);
//                 questionCounter = questionCounter + 1;
//                 const downloadCounter = {questionCounter, examCounter, totalExam: liveExams.length, totalQuestions: examData.data.data.length};
//                 setCounter(downloadCounter);

//             }

//         }

//         // const downloadCounter = {...counter , questionCounter, examCounter};
//         // setCounter(downloadCounter);
//     }
// }

// async function downloadDataFromLink(s3Url: string, link: string) {
//     let existingCache: (string | undefined)[] = [];
//     if ("caches" in window) {
//         await caches.open("cache").then(async (cache) => {
//             await cache.keys().then(res => {
//                 existingCache = res.map((data) => {
//                     return (data.url.split("/").pop())
//                 })
//             })
//         })
//     }

//     if (existingCache.includes(link)) {
//         return true
//     }
//     else {
//         const data = await makeRequestPublic(s3Url + "/" + link, RequstMethods.GET);
//         await addDataToCache(data.data, s3Url + "/" + link);
//         return true
//     }

// }

export async function addDataToCache(data: string, link: string) {
    const response_data = new Response(data);
    let cache = await caches.open('cache')
    cache.put(link, response_data);
}


export async function retrieveDataFromCache(link: string) {
    if ("caches" in window) {
        const cache = await caches.open("cache");
        const cachedResponse = await cache.match(link);

        if (cachedResponse) {
            return await cachedResponse.text(); // Or .json() if the cached data is in JSON format
        }
    }

    return null; // Return null if the data is not in the cache
}

export async function getCachedData(link: string) {
    console.log("called trying to retrieve data")
    const cachedData = await retrieveDataFromCache(link)
    if (cachedData !== null) {
        console.log("retrieved from cache")
        return cachedData;
        // Use the cached data
    } else {
        // Data not found in cache, fetch it from the server and add to cache if necessary
        // const data = await makeRequestPublic(link, RequstMethods.GET);
        console.log("downloaded from server")

        await makeRequestPublic(link, RequstMethods.GET)
            .then(async (res) => {
                if (res.status === 200) {
                    addDataToCache(res.data.data, link);
                    return res.data.data;
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }
}

export async function checkIfInCache(link: string): Promise<boolean> {
    const cachedData = await retrieveDataFromCache(link);
    return cachedData !== null;
}

export async function retrieveDataFromCacheArrayBuffer(link: string): Promise<ArrayBuffer | null> {
    if ("caches" in window) {
        const cache = await caches.open("cache");
        const cachedResponse = await cache.match(link);

        if (cachedResponse) {
            // Return the ArrayBuffer of the cached response
            return await cachedResponse.arrayBuffer();
        }
    }

    // Return null if the data is not in the cache
    return null;
}


export async function downloadAndStoreToCacheAsArrayBuffer(link: string) {
    const response = await fetch(link);
    console.log(response);
    const arrayBuffer: any = await response.arrayBuffer();
    console.log(arrayBuffer);
    addDataToCache(arrayBuffer, link);
}

export async function storeAsArrayBuffer(data: string, link: string) {
    try {
        // Decode the UTF-8 encoded string into a binary buffer
        const buffer: any = stringToBinaryArrayBuffer(data);

        console.log("Converted ArrayBuffer:", buffer);
        await addDataToCache(buffer, link);
    } catch (err) {
        console.error("Error in storeAsArrayBuffer:", err);
    }
}

function stringToBinaryArrayBuffer(data: string): ArrayBuffer {
    const buffer = new ArrayBuffer(data.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < data.length; i++) {
        view[i] = data.charCodeAt(i); // Convert each character to its binary representation
    }

    return buffer;
}
