import axios, { AxiosRequestConfig } from "axios";
import makeRequestPublic, { makeBasicCheckServer } from "../api/makeRequest.public";
import { RequstMethods } from "../api/requestMethode";
import url from "../api/urls";
import axiosHandler from "../helpers/axioHandler";
import { makeParams } from "../api/makeRequest";

export default class PublicService {
    static async getAllLiveExams() {
        return await makeRequestPublic(url.public.getAllLiveExams, RequstMethods.GET);
    }

    static async getQuestionDataByExamId(examId: string) {
        return await makeRequestPublic(url.public.getQuestionDataByExamId + "/" + examId, RequstMethods.GET);
    }

    static async checkLocalServer(localServerUrl: string) {
        let requestConfig = {
            baseURL: localServerUrl,
            url:'check',
            method: RequstMethods.GET,
            data: {}
        };
        try {
            let response = await axios.request(requestConfig);
            return response;
        } catch (error: any) {
            axiosHandler(error);
            throw error;
        }
    }

    static async setLocalServer(localServerUrl: string, payload: any) {
        let requestConfig = {
            baseURL: localServerUrl,
            url: '',
            method: RequstMethods.POST,
            data: payload
        };
        try {
            let response = await axios.request(requestConfig);
            return response;
        } catch (error: any) {
            axiosHandler(error);
            throw error;
        }
    }

    static async getFileFromLocalServer(localServerUrl: string, url: string) {
        let requestConfig: AxiosRequestConfig = {
            baseURL: localServerUrl,
            url: '/get?url=' + url,
            method: RequstMethods.GET,
            // data: payload
            headers:{
                "ngrok-skip-browser-warning": "69420"
            }
        };
        try {
            let response = await axios.request(requestConfig);
            return response;
        } catch (error: any) {
            axiosHandler(error);
            throw error;
        }
    }

}