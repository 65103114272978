import { useEffect } from 'react';

export const useKeyPress = (
  callback: (tool?: string) => void, 
  keys: KeyboardKey[], 
  elementRef: React.RefObject<HTMLElement>
) => {
  const onKeyDown = (event: KeyboardEvent) => {
    const anyKeyPressed = keys.find((key) => event.key.toLowerCase() === key);

    if (anyKeyPressed !== undefined) {
      event.preventDefault();
      callback(keyToolPair[anyKeyPressed]);
    }
  };

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      element.addEventListener('keydown', onKeyDown);

      return () => {
        element.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [onKeyDown, elementRef]);
};

export enum KeyboardKey {
  P = 'p',
  L = 'l',
  W = 'w',
  M = 'm',
  A = 'a',
  R = 'r',
  E = 'e',
  T = 't',
  D = 'd',
}

const keyToolPair = {
  p: 'Pan',
  l: 'Length',
  w: 'Wwwc',
  m: 'Magnify',
  a: 'Angle',
  r: 'RectangleRoi',
  e: 'EllipticalRoi',
  t: 'Rotate',
  d: 'Probe',
};
